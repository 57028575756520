import { addMinutes, endOfDay, startOfDay } from "date-fns";

export function generateTimeRange(
  date: string | number | Date | null = Date.now(),
  minuteStep = 30
) {
  if (date === null || date === undefined) {
    date = Date.now();
  } else if (typeof date === "string") {
    date = new Date(date).getTime();
  } else if (date instanceof Date) {
    date = date.getTime();
  }
  const timeRange: Date[] = [];
  const start = startOfDay(new Date(date));
  const end = endOfDay(new Date(start));
  while (start.getTime() < end.getTime()) {
    const currentMinute = start.getMinutes();
    const next = start.setMinutes(currentMinute + minuteStep);
    timeRange.push(addMinutes(new Date(next), -minuteStep));
  }
  return timeRange;
}

export function scheduleTick(cb: () => void, ms = 0) {
  const x = setTimeout(() => {
    cb();
    clearTimeout(x);
  }, ms);
}

export function generateDateHours(selectedDate: Date  = new Date()): { value: Date; label: Date }[] {
  const options = [];
  const date = new Date(selectedDate);
  date.setHours(0, 0, 0, 0); // Start at midnight

  for (let i = 0; i < 48; i++) {
    const hours = Math.floor(i / 2);
    const minutes = (i % 2) * 30;
    const timeDate = new Date(date);
    timeDate.setHours(hours, minutes);

    options.push({
      value: timeDate, // Store the actual Date object
      label: timeDate // Use the same Date object for the label
    });
  }

  return options;
}

export const setDateTime = (date: Date, timeInMinutes: number) => {
  const dateObj = new Date(date);
  const hours = Math.floor(timeInMinutes / 60);
  const minutes = timeInMinutes % 60;
  
  dateObj.setHours(hours, minutes, 0, 0);
  return dateObj;
}

export const getTimeInMinutes = (date: Date): number => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return (hours * 60) + minutes;
}

export const convertUTCToLocalTime = (utcDateString: string, option: Intl.DateTimeFormatOptions, timeZone: string): string => {
  const date = new Date(utcDateString);

  const options: Intl.DateTimeFormatOptions = {
    ...option,
    timeZone
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
}